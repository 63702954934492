import React from 'react'

function About() {
  return (
    <div>
      This is about 
    </div>
  )
}

export default About
